$font-sizes-300: clamp(17rem, 19.5vw + 1rem, 23rem);
$font-sizes-150: clamp(8rem, 9.6rem + 1rem, 8rem);
$font-sizes-120: clamp(6.5rem, 7.8vw + 1rem, 7.5rem);
$font-sizes-92: clamp(4rem, 5.9vw + 1rem, 5.75rem);
$font-sizes-64: clamp(3.5rem, 3.3vw + 0.33rem, 4rem);
$font-sizes-48: clamp(2rem, 3vw + 0.33rem, 3rem);
$font-sizes-42: clamp(2.3rem, 2.73vw + 0.33rem, 2.7rem);
$font-sizes-40: clamp(2.2rem, 2.6vw + 0.33rem, 2.5rem);
$font-sizes-36: clamp(1.8rem, 2.34vw + 0.33rem, 2.25rem);
$font-sizes-32: clamp(1.7rem, 2vw + 0.33rem, 2rem);
$font-sizes-28: clamp(1.4rem, 1.82vw + 0.33rem, 1.75rem);
$font-sizes-24: clamp(1.3rem, 1.5vw + 0.33rem, 1.5rem);
$font-sizes-20: clamp(1rem, 1.5vw + 0.33rem, 1.5rem);
$font-sizes-16: clamp(0.8rem, 0.9vw + 0.33rem, 1rem);
$font-sizes-14: clamp(0.7rem, 0.9vw + 0.33rem, 0.875rem);
$font-sizes-12: clamp(0.6rem, 0.7vw + 0.33rem, 0.75rem);
$font-sizes-10: clamp(0.4rem, 0.6vw + 0.33rem, 0.625rem);
