@import "../variables.scss";
@import "../typography.scss";
@import "../breakpoints.scss";

.travelExperiences {
    background-color: $custom-white;
    padding: 4rem 0;

    &Title {
        color: black;
        &Container {
            flex: 0 0 auto;
            width: 90%;
        }

        @include respond-to($upToMD) {
            font-size: 2.5rem;
        }
    }

    &SubTitle {
        @include respond-to($upToMD) {
            font-size: 1rem;
        }
    }
}

.catCardContent {
    padding-top: 1.5rem;
    padding-left: 1rem;
    color: #777E90;
    font-size: 1.1rem;

    path {
        fill: $secondary-color-three;
    }

    h4 {
        font-size: $font-sizes-32;
        font-family: "CabinetGrotesk-Extrabold";
    }

    p {
        font-size: $font-sizes-24;
        font-family: "GeneralSans-Regular";
    }
}

.catCard {
    border-radius: 24px;
    box-shadow: none;

    &:hover {
        box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.07),
            0 10px 20px -2px rgba(0, 0, 0, 0.04);
    }
}

.cardBorderQuality {
    border: 1px solid $primary-color-two;
    box-shadow: none;
    border-radius: 16px;
    font-family: "GeneralSans-Regular";
    margin-bottom: 2rem;
  }
  
  .cardBorderPremium {
    border: 1px solid $secondary-color-three;
    box-shadow: 0px 20px 60px -32px rgba(249, 187, 73, 0.12);
    border-radius: 16px;
    font-family: "GeneralSans-Regular";
    margin-bottom: 2rem;
  }

.catCardContentTitle {
    font-size: $font-sizes-32;
    font-family: "CabinetGrotesk-Extrabold";
}

.info {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: end;

    path {
        fill: $primary-color;
    }

    p {
        text-transform: uppercase;
        font-size: $font-sizes-10;
        font-family: "GeneralSans-Regular";
        margin: 0;
        padding-right: 5px;
    }
}

.iconQuality {
    svg {
        path {
            fill: #a4cde3 !important;
        }
    }
}
.iconPremium {
    svg {
        path {
            fill: #ffc061 !important;
        }
    }
}