@mixin scrollbarXHidden {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin trim($numLines: null) {
    @if $numLines != null {
        display: -webkit-box;
        -webkit-line-clamp: $numLines;
        -webkit-box-orient: vertical;
        overflow: hidden;
    } @else {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
    }
}

@mixin minWidth100vwFromXL {
    @include respond-to($fromXL) {
        min-width: 100vw;
    }
}
