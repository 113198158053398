@import "../variables.scss";
@import "../typography.scss";
@import "../breakpoints.scss";
@import "../buttons.scss";
@import "../utils.scss";

.containerInfo {
    background-color: $custom-white;
    padding: 2rem;

    @include respond-to($fromLG) {
        padding-left: 5vw;
        padding-right: 5vw;
    }

    @include respond-to($fromXL) {
        padding-left: 15vw;
        padding-right: 15vw;
    }
}

.buttonLoad {
    @include buttonLine(
        $custom-gray,
        $custom-white-two,
        $custom-gray-three,
        $primary-color
    );
}

// .ContainerCard {
//     padding-bottom: 80px;
//     height: 50rem;
// }

/* Altezza per dispositivi mobili */
@media (max-width: 991.98px) {
    .ContainerCard {
        height: 100%;
    }
}

// /* Altezza per dispositivi desktop */
// @media (min-width: 992px) {
//     .desktopPaddingBottom {
//         padding-bottom: 80px;
//     }
// }
