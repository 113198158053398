@import "../variables.scss";
@import "../buttons.scss";

.container {
    background-color: $custom-white;
}

.buttonLoad {
    @include buttonLine(
        $custom-gray,
        $custom-white-two,
        $custom-gray-three,
        $primary-color
    );
}
