@import "../variables.scss";
@import "../typography.scss";
@import "../buttons.scss";

.cardBody {
    flex: auto;
    border-radius: 25px;
    min-height: 100%;
    width: 100%;
    height: 100% !important;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.ripple {
    border-radius: 25px 25px 0px 0px !important;
    min-height: 60%;

    img {
        height: 100%;
    }

    .tag {
        z-index: 3;
        position: absolute;
        top: 5%;
        left: 5%;
        background-color: $primary-color;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        border-radius: 4px;
        padding: 0.33rem;
        color: $custom-white-two;
        text-transform: uppercase;
    }

    &:hover {
        .tag {
            display: none;
        }
    }
}
.ratingTag {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    gap: 4px;

    height: 30px;

    /* Neutrals/8 */
    background: $custom-white;
    border-radius: 24px;

    /* Inside auto layout */
    flex: 0 1 auto;
    order: 0;
}

.colorSvg {
    path {
        fill: $primary-color;
    }
}

.textPriceSmall,
.textTitleSmall,
.textPrice,
.textTitle {
    font-family: "GeneralSans-Semibold";
}

.caption,
.tag {
    font-family: "GeneralSans-Regular";
}

.caption {
    font-size: $font-sizes-14;
}

.tag {
    font-size: $font-sizes-10;
}

.textTitle {
    font-size: $font-sizes-24;
}

@mixin trim($numLines: null) {
    @if $numLines != null {
        display: -webkit-box;
        -webkit-line-clamp: $numLines;
        -webkit-box-orient: vertical;
        overflow: hidden;
    } @else {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
    }
}

.textTitleSmall,
.textTitle {
    color: $custom-black !important;
    @include trim(2);
}

.textPrice {
    font-size: $font-sizes-16;
}

.textTitleSmall {
    font-size: $font-sizes-16;
}

.tagSmall {
    font-size: $font-sizes-10;
}

.textButtonSmall,
.textPriceSmall {
    font-size: $font-sizes-14 !important;
}

.captionSmall {
    font-size: $font-sizes-12;
}

.textPriceSmall,
.textPrice {
    color: $success-color;
}

.captionSmall,
.caption {
    color: $custom-gray;

    span {
        color: $custom-black;
        font-family: "GeneralSans-Semibold";
    }
}

.buttonLike {
    background-color: $custom-white;

    path {
        fill: $custom-gray;
    }

    &:hover {
        background-color: $custom-white;

        path {
            fill: $primary-color;
        }
    }
}

.buttonLikePressed {
    background-color: $custom-white;

    path {
        fill: $primary-color;
    }

    &:hover {
        background-color: $custom-white;

        path {
            fill: $custom-gray;
        }
    }
}

.buttonDetails {
    @include buttonLine(
        $custom-white-two,
        $custom-white-two,
        $custom-gray-three,
        $primary-color
    );
}

.dispShrink,
.dispFlex {
    display: flex;
}

.dispShrink {
    flex: 0 1 auto;
    flex-direction: row;
    min-height: 0;
    justify-content: center;
    align-items: center;
}
