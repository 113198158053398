@import "../variables.scss";
@import "../typography.scss";

.backgroundWhite {
    background-color: $custom-white;
}

.backgroundWhiteTwo {
    background-color: $custom-white-two;
}

.textTitle {
    font-size: $font-sizes-32;
    font-family: "CabinetGrotesk-Extrabold";
}

.textSubTitle {
    font-size: $font-sizes-16;
    font-family: "GeneralSans-Regular";
}

.numberStep {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
}

.numberStep {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 50px;
    height: 50px;

    /* Primary/4 */
    border: 2px solid #55ce97;
    border-radius: 100px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    font-size: $font-sizes-14;
}

.cardImage,
.carouselCard {
    // width: 19rem;
    // height: 38rem;
    aspect-ratio: 5/8;
    border-radius: 32px;
}

.cardImage {
    object-fit: cover;
}

.carousel {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }

    overflow-x: auto;
    @media screen and (min-width: 575px) {
        gap: 3rem !important;
    }

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}
