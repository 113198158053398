@import "../variables.scss";
@import "../typography.scss";

.row {
  height: 90%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.imageCover {
  object-fit: cover;
}

.videoContainer {
  height: 100%;
  width: 70%;
  border-radius: 30px;
}

.container {
  height: 90vh;
  background-color: $primary-color;
  align-items: center;
  overflow: hidden;
}

.playButton {
  z-index: 3;
  background-color: $custom-white-two;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &:hover {
    background-color: $custom-white-two;
  }

  path {
    fill: $custom-gray;
  }
}

.maskOverlay {
  background-color: rgba(0, 0, 0, 0.6);
}

.textCover {
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 15s linear infinite;
  -webkit-animation: my-animation 15s linear infinite;
  animation: my-animation 15s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.scrollContainer {
  position: absolute;
  width: 100%;
  overflow: hidden;
  z-index: 2;
}

.textCover {
  font-family: "Tanker-Regular";
  font-size: $font-sizes-300;
  line-height: 110px;
  text-transform: uppercase;
  margin: 0;
  padding: 7rem 0;


  white-space: nowrap;

  /* identical to box height, or 37% */
  text-align: center;
  letter-spacing: -0.01em;

  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;

  // Border
  color: transparent;
  -webkit-text-stroke: 3px $success-color;
  text-shadow: none;
}

@media (max-width: 1400px) {
  .textCover {
    font-size: $font-sizes-300;
  }
}

@media (max-width: 767px) {
  .textCover {
    font-size: $font-sizes-150;
  }

  .videoContainer {
    width: 90%;
  }
}
