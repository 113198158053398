@import "../typography.scss";
@import "../variables.scss";

.textTitle {
  font-size: $font-sizes-24;
  font-family: "CabinetGrotesk-Extrabold";
}

.textCaption {
  font-size: $font-sizes-12;
  font-family: "GeneralSans-Semibold";

  a {
    color: $primary-color;
  }
}

.textBody {
  font-size: $font-sizes-12;
  font-family: "GeneralSans-Regular";
}

.closeButtonCart,
.closeButton {
  position: absolute;
  transform: translate(25%, -25%);
  border-radius: 50%;
  background-color: $custom-white !important;
  opacity: 1;

  &:hover,
  &:focus {
    background-color: $custom-white !important;
    opacity: 1;
  }
}

.closeButton {
  right: 0;
  top: 0;
}

.closeButtonCart {
  right: 1.5rem;
  top: 0.8rem;
}