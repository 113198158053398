@import "../variables.scss";
@import "../breakpoints.scss";
@import "../typography.scss";

.cardBody {
    border-radius: 25px;
    height: 512px;

    @include respond-to($upToSM) {
        height: 465px;
    }
}

@mixin buttonStyle($border, $backgroundColor, $color) {
    border: $border;
    background-color: $backgroundColor;
    color: $color;

    &:focus,
    &:hover {
        background-color: $backgroundColor;
        color: $color;
    }
}

.buttonDetails {
    border-radius: 3rem !important;
    font-weight: bold;
    text-transform: capitalize;
    width: 8rem;
    height: 3rem;
    font-size: $font-sizes-14;
}

.buttonDetails {
    @include buttonStyle(
        1px solid $custom-gray-three,
        transparent,
        $custom-black
    );
}

.svgImg {
    width: 5rem;
    height: 5rem;
    border: 1px solid $primary-color;
    border-radius: 50%;

    path {
        fill: $custom-black;
    }
}

.textTitle {
    font-size: $font-sizes-24;
}

.textSubTitle {
    font-size: $font-sizes-16;
}

.cardBody {
    &:hover {
        background-color: $custom-black;

        path {
            fill: $custom-white;
        }

        .textTitle {
            span {
                color: $custom-white;
            }
        }

        .textSubTitle {
            span {
                color: $custom-gray-two;
            }
        }

        .buttonDetails {
            @include buttonStyle(none, $primary-color, $custom-white);
        }
    }
}
