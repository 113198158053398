@import "../../../styles/breakpoints.scss";

.slideContainer {
    padding-right: 1rem;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-to($upToSM) {
        padding: 0;
    }

    @include respond-to($fromSM) {
        padding: 1rem;
    }
    @include respond-to($fromLG) {
        padding: 3rem 2rem;
    }
}

/* Altezza per dispositivi mobili */
.mobileHeight {
    height: "100%";
}

/* Altezza per dispositivi desktop */
@media (min-width: 992px) {
    .desktopHeight {
        height: 40rem;
    }
}
