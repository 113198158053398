@import "../variables.scss";
@import "../breakpoints.scss";
@import "../typography.scss";
@import "../utils.scss";

.mobileDiscover {
    display: none;
}

.radialContainer {
    content: "";
    z-index: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(
        to bottom,
        rgb(0, 0, 0, 0.3),
        rgb(0, 0, 0, 0.3)
    );
}

.home {
    &Head {
        background-color: $custom-white-two;
        color: $text-color;
        margin-top: 89.59px;

        @include respond-to($upToMD) {
            margin-top: 8rem;
        }

        padding-bottom: 2rem;
        @include respond-to($fromMD) {
            padding-bottom: 10rem;
        }

        &BgImg {
            background-position: center top;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            border-radius: 25px;
            margin: 0 4rem;
            @include respond-to($upToXL) {
                margin: 0 2rem;
            }
            @include respond-to($upToLG) {
                margin: 0;
            }
        }

        &Title {
            padding: 4rem 2rem 2rem 2rem;
            text-align: center;
            position: relative;
            @include respond-to($fromMD) {
                padding: 9rem 5rem 16rem 5rem;
                text-align: left;
            }

            h1 {
                font-size: $font-sizes-92;
                font-family: "Tanker-Regular";

                @include respond-to($upToMD) {
                    font-size: 5rem;
                }

                @include respond-to($XS) {
                    font-size: 3.3rem !important;
                }
            }

            h2 {
                font-size: $font-sizes-32;
                font-family: "GeneralSans-Semibold";
                margin-bottom: 3.5rem;
                @include respond-to($upToMD) {
                    font-size: 1rem;
                }
            }
        }

        &Detail {
            h4 {
                font-size: $font-sizes-24;
                font-family: "GeneralSans-Semibold";
                color: $primary-color;
                @include respond-to($upToMD) {
                    font-size: 1rem;
                    color: $custom-black-two;
                    text-align: left;
                }
            }

            p {
                font-size: $font-sizes-14;
                font-family: "GeneralSans-Regular";
                color: $custom-gray;

                @include respond-to($upToSM) {
                    text-align: left;
                }
            }
        }

        &ButtonCTA {
            padding: 1.2rem;
            font-size: $font-sizes-24;
            font-family: "GeneralSans-Semibold";
            color: $text-color;
            border: 1px solid $success-color;
            text-transform: none;
            display: flex;
            align-items: center;

            &:hover {
                color: $primary-color;
                border-color: $primary-color;
            }

            @include respond-to($upToMD) {
                width: 100%;
                font-size: $font-sizes-16;
                padding: 0.8rem;
                justify-content: space-between;
                background-color: $text-color;
                color: $custom-black-two;
                font-size: 1rem;
                border: 1px solid $text-color;
            }
        }

        &Icon {
            &Search {
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $primary-color;
                padding: 0.4rem;
                margin-left: 1rem;
                background-color: $text-color;
                padding: 1.4rem;
                width: 64px;
                height: 64px;
                @include respond-to($upToMD) {
                    padding: 0;
                    width: 48px;
                    height: 48px;
                    color: $text-color;
                    background-color: $primary-color;
                    svg {
                        transform: scale(0.8);

                        path {
                            stroke: $text-color;
                        }
                    }
                }
            }
        }
    }
}

.headButtonContainer {
    padding-bottom: 4rem;
    position: relative;
}

.headArrowCTA {
    padding: calc(1.1rem - 2px);
    border-radius: 50%;
    border-color: $text-color;
    color: $text-color;
    margin-left: 0.8rem;
    pointer-events: none;
}

.headSearchIcon,
.headWorldIcon {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headWorldIcon {
    border: 2px solid $success-color;
    padding: 0.6rem;
    margin: 1.6rem 0;
    width: 64px;
    height: 64px;
}

//HomeHead component - lower part
.headLowerContainer {
    margin: 0 4rem;
    position: relative;
}

.headLowerContentContainer {
    padding: 0 5.8rem;
    position: absolute;
    transform: translate(0, -66%);
}

.headRowCardContainer {
    box-shadow: 0px 40px 64px -32px rgba(15, 15, 15, 0.1);

    h4 {
        color: $primary-color;
    }
}

.headCardContainer {
    padding: 0 3rem;

    h4 {
        font-size: $font-sizes-24;
        font-family: "GeneralSans-Semibold";
        color: $primary-color;
    }

    p {
        font-size: $font-sizes-14;
        font-family: "GeneralSans-Regular";
        color: $custom-gray;
    }
}

.headWorldIcon {
    border: 2px solid $success-color;
    padding: 0.6rem;
    border-radius: 50%;
    margin: 1.6rem 0;
    width: 64px;
    height: 64px;
}

.headWorldIcon {
    border: 2px solid $success-color;
    padding: 0.6rem;
    border-radius: 50%;
    margin: 1.6rem 0;
    width: 64px;
    height: 64px;
}

.headWorldIcon {
    border: 2px solid $success-color;
    padding: 0.6rem;
    border-radius: 50%;
    margin: 1.6rem 0;
    width: 64px;
    height: 64px;
}

.headWorldIcon {
    border: 2px solid $success-color;
    padding: 0.6rem;
    border-radius: 50%;
    margin: 1.6rem 0;
    width: 64px;
    height: 64px;
}

//mediaquery

@media (max-width: 1200px) {
    .headCardContainer {
        padding: 0 1rem;
    }

    .headLowerContentContainer {
        padding: 0 1.8rem;
    }
}

@media (max-width: 992px) {
    .headLowerContainer {
        margin: 0;
    }
}

@media (max-width: 767px) {
    .headTitle {
        font-size: 4rem;
        margin-bottom: 0.5rem;
    }
    .headCentralContainer {
        padding-top: 6rem;
        padding-bottom: 30rem;
    }

    .headInnerContentContainer {
        padding: 0 2rem;
    }

    .headButtonContainer {
        padding-bottom: 1rem;
    }

    .headCardBorder {
        border-right: none;
    }

    .headContentContainer {
        padding: 0 0 20rem 0;
    }

    .headBgColor {
        padding-bottom: 2rem !important;
    }
    .headLowerContentContainer {
        transform: translate(0, -33%);
    }

    .headLowerContainer {
        display: none !important;
    }

    .mobileDiscover {
        display: block;
    }
}

@media (max-width: 575px) {
    .headBgColor {
        padding-bottom: 34rem;
    }

    .headLowerContentContainer {
        padding: 0 2rem;
    }
}
