@import "../variables.scss";

.container {
    background-color: $custom-white;
}

.tourReviewsSlide {
    display: flex;
    height: unset;
}

@media (max-width: 1200px) {
    .carousel {
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
        overflow-x: auto;
        &:after {
            content: "";
            display: table;
            clear: both;
        }
    }
}
