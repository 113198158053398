@import "../variables.scss";
@import "../typography.scss";

.containerCard {
    background-color: $custom-white;
    width: 90%;
}

.containerImage {
    border-radius: 20px;
    height: 12rem;
}

.image {
    border-radius: 20px;
    height: 100%;
    object-fit: cover;
}

.cardDataContainer {
    display: flex;
    border-radius: 80px;
    box-shadow: 0px 35px 21px -33px rgb(189, 189, 189);
    align-items: center;
}

.cardImageProfileContainer {
    position: relative;
    width: 3rem;
    align-items: baseline;
}

.imageProfile {
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid;
}

.tag {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    gap: 10px;

    width: 71px;
    height: 28px;

    border-radius: 10px !important;
    padding: 0.2rem;

    .tagTitle {
        text-transform: uppercase;
        font-family: $font-bold;
        color: white;
    }
}

.tag {
    font-size: $font-sizes-10;
    font-family: "GeneralSans-Regular";
}

.textTitle {
    font-size: $font-sizes-24;
    font-family: "CabinetGrotesk-Extrabold";
}

.textSubTitle {
    font-size: $font-sizes-12;
    font-family: "GeneralSans-Regular";
}

.textCardFooter {
    font-size: $font-sizes-14;
    font-family: "GeneralSans-Semibold";
}

.dot {
    height: 25px;
    width: 25px;
    background-color: $primary-color;
    border-radius: 50%;
    display: inline-block;
}
