@import "../variables.scss";
@import "../typography.scss";
@import "../buttons.scss";
@import "../breakpoints.scss";
@import "../utils.scss";

.italianTour {
    &Title {
        &Container {
            flex: 0 0 auto;
            width: 41%;

            @include respond-to($upToLG) {
                width: 100%;
            }
        }

        @include respond-to($upToMD) {
            font-size: 2.5rem;
        }
    }
    &Paragraph {
        @include respond-to($upToMD) {
            font-size: 1rem;
        }
    }
    &Content {
        background-color: $custom-white;
        border-radius: 25px;
        @include respond-to($upToLG) {
            background-color: $custom-white;
            border-radius: 0;
        }
    }
    &Btns {
        .btn {
            font-size: $font-sizes-14;
            min-width: max-content;
            padding: 12px 16px;
        }

        @include respond-to($upToLG) {
            @include scrollbarXHidden;
            overflow-x: auto;
        }

        @include respond-to($upToXS) {
            padding-left: 0;
        }
    }
    &Card {
        margin: 0 2rem 0 0;

        @include respond-to($fromLG) {
            margin: 0;
        }
    }
}

.buttonDetails,
.buttonNotSelected,
.buttonSelected {
    border-radius: 25px !important;
    font-family: "GeneralSans-Semibold";
    text-transform: none;
}

.buttonNotSelected {
    border: 2px solid;
    background-color: transparent;
}

.buttonSelected {
    background-color: $success-color;
    color: $custom-black;

    &:hover {
        background-color: $success-color;
        color: $custom-black;
    }

    &:focus,
    &:active {
        background-color: $success-color;
        color: $custom-black;
    }
}

.buttonNotSelected {
    border-color: $custom-gray;
    color: $custom-gray;

    &:hover {
        background-color: transparent;
        border-color: $success-color;
        color: $success-color;
    }
}

.buttonMainAction {
    @include buttonLine(
        $primary-color,
        $primary-color,
        $primary-color,
        transparent,
        $primary-color
    );
}

.textSubTitle {
    font-size: 1.1rem;
}

.italianTourBtns {
    display: flex;
    padding-bottom: 20px;
    justify-content: start;
    gap: 16px;
    @include respond-to($fromMD) {
        justify-content: center;
    }
    @media screen and (min-width: 750px) {
        justify-content: center;
    }
}

/* Altezza per dispositivi mobili */
.mobileHeight {
    height: "100%";
}

/* Altezza per dispositivi desktop */
@media (min-width: 992px) {
    .desktopHeight {
        height: 40rem;
    }
}
