@import "../variables.scss";
@import "../typography.scss";
@import "../utils.scss";

.container {
    border-radius: 16px;
    min-width: 15rem;
    display: flex;
    flex-direction: column;
    width: auto;
    max-width: 100%;
    flex-grow: 1;
}

.textName,
.textComment {
    font-family: "GeneralSans-Semibold";
    color: $custom-black-two;
    white-space: nowrap;
    // text-overflow: ellipsis;
    width: 100%;
}
.textWork {
    font-family: "GeneralSans-Regular";
    color: $custom-gray-two;
}

.textComment {
    font-size: $font-sizes-16;
    @include trim(7);
    white-space: pre-wrap;
    text-overflow: unset;
    overflow: visible;
    display: block;
}

.textWork,
.textName {
    font-size: $font-sizes-14;
}

.image_outer_container {
    border-radius: 50%;
    display: block;
    position: relative;
    width: 4rem;
    height: 4rem;
    margin-right: 2rem;
}

.image_inner_container {
    border-radius: 50%;
    height: 100%;
}

.image_inner_container img {
    border-radius: 50%;
    aspect-ratio: 1 / 1;
}

.image_inner_container img,
.imageCover {
    width: 48px;
    height: 48px;
    object-fit: cover;
}

.footerContent {
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-width: 0;
}

.textWrapper {
    flex-grow: 1;
    min-width: 0;
}
