$primary-color: #ed6f6f;
$primary-color-two: #94d9ff;
$primary-color-three: #92a5ef;

$success-color: #55ce97;
$error-color: #ed6f6f;

$secondary-color: #a4cde3;
$secondary-color-two: #f9f3eb;
$secondary-color-three: #ffc061;
$secondary-color-four: #ff9c3f;
$custom-black: #282838;
$custom-black-two: #23262f;
$custom-black-three: #353945;
$custom-black-fuor: #83899a;
$custom-gray: #777e91;
$custom-gray-two: #b1b5c4;
$custom-gray-three: #e6e8ec;
$custom-white: #f5f5f5;
$custom-white-two: #fcfcfd;
$custom-green: #d3eedc;
$custom-blue: #3772ff;
$text-color: #ffffff;

// Breakpoints
$breakpoint-mobile-sm: 357px;

//font
$font: "GeneralSans-Regular";
$font-semiregular: "GeneralSans-SemiRegular";
$font-bold: "GeneralSans-SemiBold";

//utils
$border: 2px solid $custom-gray-three;
$border-primary: 2px solid $primary-color;
$border-light: 2px solid $custom-white;
$border-black: 2px solid $custom-black-two;
$border-green: 2px solid $success-color;

$shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.1);
