@import "./typography.scss";

@mixin buttonLine(
    $color,
    $hover-color,
    $border-color,
    $hover-bg-color,
    $hover-border-color: transparent,
    $size: $font-sizes-16
) {
    border-radius: 25px !important;
    font-family: "GeneralSans-Semibold";
    text-transform: capitalize;
    font-size: $size;
    border: 2px solid;
    background-color: transparent;
    border-color: $border-color;
    color: $color;

    &:hover {
        background-color: $hover-bg-color;
        border-color: $hover-border-color;
        color: $hover-color;
    }

    &:focus,
    &:active {
        background-color: transparent;
        border-color: $border-color;
        color: $color;
    }
}

@mixin buttonFilled(
    $color,
    $hover-color,
    $background-color,
    $hover-bg-color,
    $size: $font-sizes-16
) {
    border-radius: 25px !important;
    font-family: "GeneralSans-Semibold";
    text-transform: capitalize;
    font-size: $size;
    background-color: $background-color;
    color: $color;

    &:hover,
    &:focus,
    &:active {
        background-color: $hover-bg-color;
        color: $color;
    }
}

@mixin buttonFilled(
    $color,
    $hover-color,
    $background-color,
    $hover-bg-color,
    $size: $font-sizes-16
) {
    border-radius: 25px !important;
    font-family: "GeneralSans-Semibold";
    text-transform: capitalize;
    font-size: $size;
    background-color: $background-color;
    color: $color;

    &:hover,
    &:focus,
    &:active {
        background-color: $hover-bg-color;
        color: $color;
    }
}
