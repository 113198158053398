@import "../variables.scss";
@import "../buttons.scss";
@import "../typography.scss";

.brochuresContainer {
    background-color: $custom-black;
}

.dialog {
    max-width: 90%;
    min-height: 80vh;
    margin: 10vh 5%;

    @media (min-width: 1000px) and (max-height: 600px) {
        max-width: 95%;
        min-height: 95vh;
        margin: 2.5vh 2.5%;
    }
}

.modalBody {
    padding: 0 5vw;

    @media (min-width: 1000px) and (max-height: 600px) {
        padding: 0 2.5vw;
    }
}

.modalContainer {
    background-color: $primary-color;
    height: 90%;
    margin: 10rem;
    border-radius: 3rem;
}

.overlayMask {
    background-color: rgba($primary-color, 0.6);
    border-radius: 3rem;
}

.buttonClose {
    background-color: $custom-white;
    z-index: 2;
    top: 3%;
    right: 3%;

    path {
        fill: $custom-gray;
    }

    &:hover {
        background-color: $custom-white;

        path {
            fill: $primary-color;
        }
    }
}

.buttonDetails {
    padding: 16px 24px !important;
    @include buttonLine(
        $custom-gray,
        $custom-white-two,
        $custom-gray-three,
        $primary-color
    );
    border-radius: 90px !important;

    path {
        fill: $custom-white-two;
    }
}

.textTitle {
    font-size: $font-sizes-48;
}

.textSubTitle {
    font-size: $font-sizes-16;
}

@media (max-width: 767px) {
    .textTitle {
        font-size: 2.5rem;
    }

    .modalContainer {
        width: 100% !important;
        margin: 0 !important;
    }
}

@media (max-width: 576px) {
    .dialog {
        max-width: 100%;
        min-height: 90vh;
        margin: 5vh 0;
    }
}
