@import "../variables.scss";
@import "../typography.scss";
@import "../buttons.scss";

.containerCard {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center
}

.buttonSocial {
    @include buttonLine(
        $custom-gray,
        $custom-white-two,
        $custom-gray-three,
        $primary-color
    );
}

.textTitle {
    font-size: $font-sizes-48;
    line-height: 23px;
    margin-bottom: 24px;
}

.textSubTitle {
    font-size: 20px;
}

.bottomCardContentTitle {
    font-size: 20px;
    color: $custom-gray;
    text-align: center;
    font-family: $font;
}
.boldTitle {
    font-size: 20px;
    color: $custom-gray;
    font-weight: bold;
    font-family: $font;
}

.bottomCardContentTitleSmall {
    font-size: 20px;
    color: $custom-gray;
    text-align: center;
    font-family: $font;
}

.accordionTitle {
    color: $primary-color;
    font-size: 20px;
    font-family: $font-bold;
    margin:0
}

.accordionText {
    font-size: 18px;
    color: $custom-gray;
    text-align: start;
    font-family: $font;
}

.accordionItem {
    box-shadow: none;
    border: none;
    color: $custom-gray;
    font-family:  $font;

    Button {
        box-shadow: none !important;
        padding-bottom: 20;
        padding-left: 0;
        padding-right: 0;

    }

    div {
        padding:0;
    }

}