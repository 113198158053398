@import "../variables.scss";
@import "../breakpoints.scss";

.cardVisit {
    &Footer {
        height: 6rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.cardImage,
.innerCard {
    border-radius: 20px;
    max-width: 100%;
}

.cardImage {
    min-height: 50vh;
    max-height: 30rem;
    object-fit: cover;

    @include respond-to($upToSM) {
        height: 50vh;
    }
    @include respond-to($upToLG) {
        height: 60vh;
        max-height: auto;
    }
    @include respond-to($upToXS) {
        height: 40vh;
    }
}

.ico {
    path {
        fill: $primary-color;
    }
}

.textTitle {
    font-family: "GeneralSans-Semibold";
}

.textSubTitle {
    font-family: "GeneralSans-Regular";
}
