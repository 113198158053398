@import "../variables.scss";
@import "../typography.scss";
@import "../utils.scss";

.container {
  aspect-ratio: 3/5;
  border-radius: 12px;
}

.mask_overlay {
  background-color: rgba(0, 0, 0, 0.6);
}

.textName {
  font-size: $font-sizes-16;
  font-family: "GeneralSans-Semibold";
  color: $custom-white-two;
  @include trim(1);
  padding-right: 0.6rem;
}

.textPlace {
  font-size: $font-sizes-12;
  font-family: "GeneralSans-Regular";
  color: $custom-gray-two;
}

.play_button {
  position: absolute;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  z-index: 3;
  background-color: $custom-black-two;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &:hover {
    background-color: $custom-black-two;
  }

  path {
    fill: $custom-white-two;
  }
}

.image_outer_container {
  border-radius: 50%;
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
}

.image_inner_container {
  border-radius: 50%;
  padding: 2px;
  background: $primary-color;
  height: 100%;
}

.image_inner_container img {
  border-radius: 50%;
  aspect-ratio: 1 / 1;
}

.image_inner_container img,
.imageCover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
