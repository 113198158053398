@import "../variables.scss";
@import "../breakpoints.scss";
@import "../buttons.scss";
@import "../typography.scss";
@import "../utils.scss";

.carouselContainter {
    max-height: 30rem;
}

.imageCover {
    object-fit: cover;
}

.cardSmartSubtitle {
    @include trim(2);

    @media screen and (max-height: 899px) {
        font-size: $font-sizes-16;
    }
    @media screen and (min-height: 900px) {
        @include trim(3);
    }
}

.cardSmartSelection {
    @include respond-to($fromXXL) {
        max-height: 5rem;
        overflow: auto;
        height: 100%;
    }
}

.modalSize {
    @include respond-to($fromXXL) {
        height: 90%;
        width: 90%;
        max-width: 1920px;
        max-height: 1080px;
        overflow: hidden !important;
    }
    @media screen and (max-height: 899px) {
        max-width: 100vw;
        height: 100%;
        width: 100%;
        margin: 0;
    }
}

.cardSmartTourContainer {
    height: 100%;
}

.cardContainer {
    &:hover {
        .title {
            -webkit-animation-name: goUp;
            animation-name: goUp;
        }

        .description {
            display: block;
            -webkit-animation-name: fadeInUp;
            animation-name: fadeInUp;
        }

        .linearGradient {
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.789),
                rgba(255, 255, 255, 0.1)
            );
            border-radius: 1.25rem;
            -webkit-animation-name: fadeInUp;
            animation-name: fadeInUp;
        }

        .buttonDetails {
            background-color: $primary-color;
            border-color: transparent;
            color: $custom-white-two;
        }
    }
}

.buttonDetails {
    @include buttonLine(
        $custom-white-two,
        $custom-white-two,
        $custom-gray-three,
        $primary-color
    );

    path {
        fill: $custom-white-two;
    }
}

.buttonClose {
    background-color: $custom-white;

    &:hover,
    &:focus {
        background-color: $custom-white;
    }
}

.buttonCloseDark {
    background-color: transparent;
    border: 2px solid $custom-gray;
    path {
        fill: $custom-white-two;
    }

    &:hover,
    &:focus {
        background-color: transparent;
        border: 2px solid $custom-gray;
    }
}

.carouselLabel,
.normalText {
    font-family: "GeneralSans-Regular";
    font-size: $font-sizes-14;
    font-style: normal;
    color: $custom-gray-two;
    margin: 0;
}

.carouselLabel {
    text-transform: uppercase;
}

.gridLabel,
.label {
    font-family: "GeneralSans-Semibold";
    font-size: $font-sizes-16;
    font-style: normal;
    color: $custom-white-two;
}

.label {
    font-size: $font-sizes-16;
}

.gridLabel {
    font-size: $font-sizes-24;
}
.bgColor {
    background-color: $primary-color;
}

.gridCard {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    cursor: pointer;
    @include respond-to($fromLG) {
        background-color: rgba($primary-color, 1);
        &:hover {
            background-color: rgba(0, 0, 0, 0.5);
            animation: fadeoutBg 1s; /***Transition delay 3s fadeout is class***/
            -moz-animation: fadeoutBg 1s; /* Firefox */
            -webkit-animation: fadeoutBg 1s; /* Safari and Chrome */
            -o-animation: fadeoutBg 1s;
        }
    }
}

.container {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                          
    supported by Chrome, Edge, Opera and Firefox */
    height: 100%;
    background-size: cover !important;
    background-position: bottom !important;
    padding: 1rem;

    @include respond-to($fromXXL) {
        border-radius: 1rem;
        padding: 2.5rem;
    }

    @media screen and (max-height: 899px) {
        border-radius: 0;
        padding: 1rem;
    }
}

.buttonBack {
    @include buttonLine(
        $custom-white,
        $custom-white,
        $custom-gray,
        transparent,
        $custom-gray,
        $font-sizes-14
    );

    path {
        fill: $custom-white;
    }
}

.customCheckboxChecked {
    &:checked {
        background-color: $custom-white !important;
        border-color: $custom-white !important;
    }

    &:before {
        background-color: $primary-color !important;
        box-shadow: 0px 0px 0px 13px $primary-color !important;
        border-color: $primary-color !important;
        &:checked {
            background-color: $primary-color !important;
            box-shadow: 0px 0px 0px 13px $primary-color !important;
            border-color: $primary-color !important;
        }
        &:focus {
            background-color: $primary-color !important;
            box-shadow: 0px 0px 0px 13px $primary-color !important;
            border-color: $primary-color !important;
        }
    }
}

.title {
    font-family: "CabinetGrotesk-Extrabold";
    text-transform: capitalize;
    font-size: $font-sizes-40;
    color: $custom-white-two;
}

.description {
    font-family: "GeneralSans-Regular";
    font-size: $font-sizes-14;
    font-style: normal;
    color: $custom-gray-three;
}

.description {
    display: none;
}

.radioButtonMContainer {
    overflow-y: auto;
    overflow-x: hidden;
}

.smartSearchMFilterDesc {
    @media (max-height: 610px) {
        display: none;
    }
}

.title,
.description {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes goUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@keyframes fadeoutBg {
    from {
        background-color: rgba($primary-color, 1);
    } /** from color **/
    to {
        background-color: rgba(0, 0, 0, 0.3);
    } /** to color **/
}

@-moz-keyframes fadeoutBg {
    /* Firefox */
    from {
        background-color: rgba($primary-color, 1);
    }
    to {
        background-color: rgba(0, 0, 0, 0.3);
    }
}

@-webkit-keyframes fadeoutBg {
    /* Safari and Chrome */
    from {
        background-color: rgba($primary-color, 1);
    }
    to {
        background-color: rgba(0, 0, 0, 0.3);
    }
}

@-o-keyframes fadeoutBg {
    /* Opera */
    from {
        background-color: rgba($primary-color, 1);
    }
    to {
        background-color: rgba(0, 0, 0, 0.3);
    }
}
